




















import { Store, TAwait } from '@/store/store';
import Component from 'vue-class-component';
import TableLoading from './TableLoading.vue'

@Component({
  components:{
    TableLoading
  }
})
export default class BillingHistory extends Store {
  fields = [
    {
      key: 'number',
      label: 'Invoice Number',
    },
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'total',
      label: 'Total',
    },
    {
      key: 'status',
      label: 'Status',
      tdClass: 'status'
    },
    {
      key: 'view',
      label: '',
      tdClass: 'w10',
    },
  ]
  items: TAwait<ReturnType<typeof Store.prototype.getBillingHistory>> = []
  
  async created(){
    this.items  = await this.getBillingHistory()
  }
}
