



















































































































































































// @vuese
// @group Forms

import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Store,  } from "@/store/store";

@Component
/**
 * Generic form that appends styling to the form
 */
export default class TableLoading extends Store {
  @Prop({default: 'table'}) readonly tableId?: string;
  @Prop({default: []}) readonly fields?: Array<any> | undefined;
  @Prop({default: ['none']}) readonly items?: Array<any> | undefined;

  /**
   * Returns the status of the account node
   * @arg {any} item - Account details
  */
  getStatus(item: any) {
    if(item.status) {
      return item.status;
    } else {
      return item.is_pending ? 'pending' : 'verified';
    }
  }
  /**
   * Returns the is_pending status of the email
   * @arg {any} item - Account details
  */
  getVerifiedStatus(item: any) {
    return item.is_pending ? 'pending' : 'verified';
  }
}
