












import Component from "vue-class-component";
import BillingHistory from "@/components/tables/BillingHistory.vue"
import Vue from "vue";

@Component({
  components:{
    BillingHistory
  },
  metaInfo: {
    title: 'Order Historys',
  }
})
export default class OrderHistory extends Vue {
  componentTitle = 'Order History'
}
